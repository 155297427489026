.description-field{
    height: 200px;
    width: 90%;
    font-size: 1.5em;
    border: none;
   
    /* font-family:Arial, Helvetica, sans-serif; */
}

input{
    border: none;
    border-bottom: 1px solid #444941;
    background-color:#D5EEBB
}

textarea{
    margin-top: 5px;
    border-color: #444941;
    background-color:#D5EEBB ;
    width: 90%;
}

.explanation-field{
    height: 100px;
    width: 90%;
    font-size: 1.3em;
    font-family:Arial, Helvetica, sans-serif;
}

.input-field{
    height: 50px;
    width: 90%;
    font-size: 1.5em;
    color: #444941;
    /* padding-left: 2px; */
}

.password{
    height: 50px;
    width: 550px;
    font-size: 1.5em;

}
.date-field{
    height: 50px;
    width: 70px;
    font-size: 1.2em;
    margin-left: 10px;
}

.note-field{
    height: 100px;
    width: 75%;
    font-size: 1em;
}

.price-field{
    height: 30px;
    width: 80%;
    font-size: 1.1em;
}

/* .label-item{
    position: absolute;
} */

@media(max-width: 380px){
    .input-field{
        height: 50px;
        width: 175px;
        font-size: 1.1em;
    }
    
    .description-field{
        height: 100px;
        width: 250px;
        font-size: 1.1em;
        font-family:Arial, Helvetica, sans-serif;
    }

    .password{
        height: 50px;
        width: 250px;
        font-size: 1.1em;
    }

    .price-field{
        height: 50px;
        width: 250px;
        font-size: 1.1em;
    }

    label{
        width: 1%;
        left: 5px;
    }

    .explanation-field{
        height: 100px;
        width: 100%;
        font-size: 1.3em;
        font-family:Arial, Helvetica, sans-serif;
    }

    
}



