#footer{
    border-top: 1px solid black;
    height: 100px;
    text-align: center;
}

#copyright{
    /* display: flex; */

    font-size: 1.2em;
    

    
}