#contact-form{
    margin: auto;
    /* padding: 2%; */
    width: 60%;
    margin-top: 3%;
    /* margin-bottom: 3%; */
    border-top: 5px solid #444941;
    padding-left: 6%;
    
}




#send-button{
    background-color:#D5EEBB;
    border: 1px solid #444941;
    /* display: block; */
    /* margin: auto; */
    width: 15%;
    /* height: 60px; */
    font-size: 1.5em;
    border-radius: 20px;
    /* border: none; */
}

::placeholder{
    color: #444941;
}

#send-button:hover{
    background-color: #444941;
    border: 1px solid #444941;
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    width: 15%;
    color:#D5EEBB
    /* height: 60px; */;
    font-size: 1.5em;
    border-radius: 20px;
    /* border: none; */
}

#check-mark{
    width: 3%;
}

h3 #sending{
    display: flex;
    margin-left: auto;
    
}

#form-div h3{
    display: flex;
    margin: auto;
}




@media(max-width: 915px){
    #send-button{
        
        width: 35%;
      
    }
}
@media(max-width: 770px){
    
}
@media(max-width: 653px){
    
}
@media(max-width: 540px){
   
}
@media(max-width: 425px){
    #send-button{
        
        width: 45%;
      
    }
}
@media(max-width: 375px){
    #send-button{
        
        width: 45%;
      
    }
}
@media(max-width: 360px){
    #contact-form{
        margin: auto;
        /* padding: 2%; */
        width: 60%;
        margin-top: 3%;
        /* margin-bottom: 3%; */
        border-top: 5px solid #444941;
        padding-left: 6%;
        
    }
}
@media(max-width: 425px){
   #check-mark{
        width: 10%;
        /* display: none; */
   }
}
@media(max-width: 325px){
   #check-mark{
        /* width: 5%; */
        display: none;
   }
}