

img{
    width: 100%;
}

.work-image{
    width: 100%;
}

#rows{
    overflow: scroll;
}

.row-1{
    display: flex;
}
.row-2{
    display: flex;
}

#rows::-webkit-scrollbar {
    display: none;
  }

@media (max-width: 425px) {
    .row-1{
        display: block;
    }
    .row-2{
        display: block;
    }

    
}


