.navbar{
    background-color: #D5EEBB;
    color: #444941;
    text-align: center;
    border-bottom: 1px solid #5F7A61;

}

.nav-item{
    color: #444941;
    width: 500px;
}


.nav-link{
    width: 170px;
    text-align: center;
    font-size: 22px;
}

.navbar-brand{
    width: 30%;
    height: 100px;
}

.header-logo{
    width: 70%;
   
}


.navtext{
    color: #F6F6E9;
    font-size: 2.5em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 1.5px;
    font-weight: 100;
}

.nav-item{
    width: 20%;
}


.dropdown-menu.show{ 
    background-color: #444941;
}

@media(max-width: 1280px){
    .header-logo{
        width: 70%;
        margin-left: -50px;
        /* margin-top: px; */
        
    }

    
}
/* @media(max-width: 1180px){
    .header-logo{
        width: 250%;
        margin-left: -110px;
        margin-top: 10px;
        
    }

    
} */
@media(max-width: 1024px){
    .header-logo{
        width: 100%;
        margin-left: -30px;
        /* margin-top: 1px; */
        
    }

    
}
@media(max-width: 915px){
    .header-logo{
        width: 130%;
        margin-left: 0px;
        margin-top: 0px;
        
    }
}
@media(max-width: 770px){
    .header-logo{
        width: 130%;
    }
}
@media(max-width: 653px){
    .header-logo{
        width: 150%;
    }
}
@media(max-width: 540px){
    .header-logo{
        width: 150%;
    }
}
@media(max-width: 415px){
    .header-logo{
        width: 200%;
        margin-left: 0px;
        margin-top: 0px;
    }
}
@media(max-width: 375px){
    .header-logo{
        width: 200%;
        margin-left: 0px;
        margin-top: 0px;
    }
}
@media(max-width: 360px){
    .header-logo{
        width: 200%;
    }
}
@media(max-width: 280px){
    .header-logo{
        width: 250%;
    }
}
