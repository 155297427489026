#home{
    font-family: Optima;
    /* font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif; */
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-weight:300;
    background-color: #D5EEBB;
    color: #444941;
}

#products{
    display: flex;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    font-size: 250%;
}

#prod-items{
    font-family:Didot;
    display: flex;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

#logo{
    display: block;
    width: 30%;
    /* padding: auto; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 15px;
}

li{
    margin-left: 40%;
}

.seperation{
    /* height: 1px; */
    margin: auto;
    border: 1px solid #5F7A61;
    width: 80vw;
    margin-top: 5%;
}
.seperation-2{
    /* height: 1px; */
    margin: auto;
    border: 1px solid #5F7A61;
    width: 80vw;
    margin-top: 3%;
    margin-bottom: 5%;
}
 .text-center{
    margin-left: -1%;
}

/* .prod-space{
    margin-left: 1%;
     margin-right: 1%;
} */

#prod-web a{
    color: #444941;
    text-decoration: none;
}

#prod-post a{
    color: #444941;
    text-decoration: none;
}

#prod-phot a{
    color: #444941;
    text-decoration: none;
}
#prod-serv a{
    color: #444941;
    text-decoration: none;
}

#welcome-message{
    margin-top: 3%;
    
}

#welcome-message h1{
    font-family: Didot;
    font-size: 4em;
    text-shadow: 10px;
}

#welcome-message h2{
    font-family: Didot;
    font-size: 3em;
    text-shadow: 10px;
}

#welcome-message p{
    font-size: 1.5em;
    margin: auto;
    text-align: center;
    width: 80vw;
}

#home-images-parent{
    /* display: flex; */
    margin: auto;
    width: 75vw;
    /* height: 450px; */
    border: 1px solid #444941 ;
    overflow: scroll;
}

#home-images-parent::-webkit-scrollbar {
    display: none;
  }
  





#prod-web{
    /* margin-left: 20%; */
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
    transition: all 0.2s ease 0s;
}

.space{
    height: 50px;
}



.hover-zoom img {
    
    width: 100%;
  }
  
  #prod-web:hover, #prod-post:hover, #prod-phot:hover {
    transform: scale(1.1);
  }

#prod-post{
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
         transition: all 0.2s ease 0s;
}

#prod-web{
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
     -ms-animation: fadein 3s; /* Internet Explorer */
      -o-animation: fadein 3s; /* Opera < 12.1 */
         animation: fadein 3s;
         transition: all 0.2s ease 0s; 
}

#prod-phot{
    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 4s; /* Firefox < 16 */
     -ms-animation: fadein 4s; /* Internet Explorer */
      -o-animation: fadein 4s; /* Opera < 12.1 */
         animation: fadein 4s;
         transition: all 0.2s ease 0s;
}
#prod-serv{
    -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 5s; /* Firefox < 16 */
     -ms-animation: fadein 5s; /* Internet Explorer */
      -o-animation: fadein 5s; /* Opera < 12.1 */
         animation: fadein 5s;
         transition: all 0.2s ease 0s;
}

#prod-space-1{
    -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.5s; /* Firefox < 16 */
     -ms-animation: fadein 1.5s; /* Internet Explorer */
      -o-animation: fadein 1.5s; /* Opera < 12.1 */
         animation: fadein 1.5s;
}

#prod-space-2{
    -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2.5s; /* Firefox < 16 */
     -ms-animation: fadein 2.5s; /* Internet Explorer */
      -o-animation: fadein 2.5s; /* Opera < 12.1 */
         animation: fadein 2.5s;
}

#prod-space-3{
    -webkit-animation: fadein 3.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3.5s; /* Firefox < 16 */
     -ms-animation: fadein 3.5s; /* Internet Explorer */
      -o-animation: fadein 3.5s; /* Opera < 12.1 */
         animation: fadein 3.5s;
}

a:link{
    color: #444941;
    text-decoration: none;
}



#contact-button{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    
}

#contact-box{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #444941;
    width: 170px;
    height: 70px;
    border-radius: 10px;
    font-size: 2em;
    font-weight: 500;
    margin-top: 15px;
}

#contact-box p{
    display: flex;
    margin: auto;
    
   
}

#contact-box:hover{
    background-color: #444941;
    color: #D5EEBB;
    border: 1px solid #444941;
    text-decoration: none;
}

.ig-logo{
    display: flex;
    height:150px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media(max-width: 1024px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 200%;
        font-weight: 300;
    }
    
}
@media(max-width: 915px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 200%;
        font-weight: 300;
    }
    #logo{
        display: block;
        width: 30%;
        /* padding: auto; */
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        margin-bottom: 15px;
    }
    #home-images-parent{
        display: block;
        margin: auto;
        width: 75vw;
        height: 450px;
        border: 1px solid #444941 ;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
@media(max-width: 770px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 170%;
        font-weight: 300;
    }
}
@media(max-width: 653px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 150%;
        font-weight: 300;
    }
}
@media(max-width: 540px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 130%;
        font-weight: 300;
    }

    #logo{
        width: 60%;
    }
}
@media(max-width: 425px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 90%;
        font-weight: 300;
    }

    #logo{
        width: 60%;
    }

    #home-images-parent{
        display: block;
        margin: auto;
        width: 75vw;
        height: 450px;
        border: 1px solid #444941 ;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
@media(max-width: 375px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 85%;
        font-weight: 300;
    }

    #home-images-parent{
        display: block;
        margin: auto;
        width: 75vw;
        height: 450px;
        border: 1px solid #444941 ;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #logo{
        width: 60%;
    }
}
@media(max-width: 360px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 93%;
        font-weight: 300;
    }

    #logo{
        width: 70%;
    }
}
@media(max-width: 320px){
    #products{
        display: flex;
        /* width: 100%; */
        margin-left: auto;
        margin-right: auto;
        font-size: 70%;
        font-weight: 300;
    }
}